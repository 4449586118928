import store from '@/store/index';
const storeState=(store.state as any);
const fieldName={
    //人员信息 从业轨迹
    id:{name:"ID",type:"text",width:"120px"},
    name: {name:"名称",type:"input",width:"120px"},
    companyId: {name:"机构ID",type:"input",width:"160px"},
    authType: {name:"证件类型",type:"select",width:"120px",option:storeState.authType},
    politicalStatus: {name:"政治面貌",type:"select",width:"180px",option:storeState.politicalStatus},
    idNumber: {name:"证件号码",type:"input",width:"220px"},
    practiceNumber:{name:"执业证号码",type:"input",width:"280px"},
    mobileNumber:{name:"电话号码",type:"input",width:"160px"},
    email: {name:"邮箱",type:"input",width:"160px"},
    creditRealScore: {name:"诚信评分",type:"input",width:"100px"},
    creditCreateTime: {name:"诚信评分时间",type:"dateTime",width:"180px"},
    sex:{name:"性别",type:"select",width:"100px",option:storeState.sex},
    birthday: {name:"出生日期",type:"dateTime",width:"200px"},
    age:{name:"年龄",type:"input",width:"100px"},
    realAge:{name:"真实年龄",type:"input",width:"100px"},
    education:{name:"学历",type:"select",width:"180px",option:storeState.education},
    nativePlace: {name:"籍贯",type:"input",width:"100px"},
    digitgd:{name:"是否关联粤省事",type:"select",width:"120px",option:[{value:false,label:"否"},{value:true,label:"是"}]},
    digitgdCount:{name:"粤省事累计使用次数",type:"input",width:"160px"},
    digitgdFirstTime: {name:"粤省事首次关联时间",type:"dateTime",width:"160px"},
    digitgdLastTime: {name:"粤省事最后关联时间",type:"dateTime",width:"160px"},
    createdUser: {name:"创建人ID",type:"input",width:"100px"},
    createdTime: {name:"创建时间",type:"dateTime",width:"180px"},
    updatedUser: {name:"更新人ID",type:"input",width:"100px"},
    updatedTime: {name:"更新时间",type:"dateTime",width:"180px"},
    jobRecordId: {name:"从业轨迹ID",type:"input",width:"120px"},
    //从业轨迹
    lastCompany: {name:"上家公司",type:"input",width:"200px"},
    lastPracticeNumber: {name:"上家公司的执业证编号",type:"input",width:"200px"},
    nextCompany: {name:"下家公司",type:"input",width:"200px"},
    nextPracticeNumber:  {name:"下家公司的执业证编号",type:"input",width:"180px"},
    recordId: {name:"记录ID",type:"input",width:"100px"},
    state:  {name:"在职情况",type:"select",width:"120px",option:[{value:1,label:"在职"},{value:2,label:"离职"}]},
    workNumber:  {name:"工号",type:"input",width:"100px"},
    position: {name:"职位",type:"input",width:"100px"},
    memberType: {name:"人员类型",type:"select",width:"120px",option:[{value:"Y",label:"员工"},{value:"X",label:"销售从业人员"}]},
    contractType: {name:"合同类型",type:"select",width:"140px",option:storeState.contractType},
    entryDate:  {name:"入职时间",type:"dateTime",width:"180px"},
    departureDate:  {name:"离职时间",type:"dateTime",width:"180px"},
    registerDate:  {name:"登记时间",type:"dateTime",width:"180px"},

    //保证书
    memberId:  {name:"用户ID",type:"input",width:"100px"},
    openid: {name:"微信Openid",type:"text",width:"120px"},
    wechatPhoto:  {name:"微信头像",type:"text",width:"120px"},
    signed: {name:"是否签署",type:"select",width:"120px",option:[{value:false,label:"否"},{value:true,label:"是"}]},
    signature:{name:"签名",type:"text",width:"200px"},
    recognizance: {name:"保证书",type:"text",width:"220px"},
    signingTime:  {name:"签署时间",type:"dateTime",width:"180px"},
    //业务指标
    dataType: {name:"数据类型",type:"select",width:"140px",option:[{ label: "在职", value: 1 },{ label: "离职", value: 2 }]},
    year: {name:"所属年度",type:"input",width:"140px"},
    premium:{name:"累计保费（万元）",type:"input",width:"160px"},
    policyCount: {name:"累计保单件数",type:"input",width:"140px"},
    persistency: {name:"保单继续率",type:"input",width:"140px"},
    complaintCount: {name:"投诉件数",type:"input",width:"140px"},
    effectiveCount: {name:"有效保单件数",type:"input",width:"140px"},
    backVisit: {name:"回访成功率",type:"input",width:"140px"},
    cxtbl: {name:"长险退保率",type:"input",width:"140px"},
    cxtrtbjzb: {name:"长险通融退保件占比",type:"input",width:"160px"},
    cxdcl: {name:"长险短出率",type:"input",width:"140px"},
    tycylcl: {name:"团队成员留存率",type:"input",width:"160px"},
    description: {name:"说明",type:"input",width:"140px"},
    // 业绩指标-在职导入
    businessStartDate:{name:"业绩统计起期",type:"dateTime",width:"180px"},
    businessEndDate:{name:"业绩统计止期",type:"dateTime",width:"180px"},
    memberCompanyId:{name:"从业轨迹ID",type:"input",width:"100px"},
    newPremium:{name:"最近一年新单保费/万元",type:"input",width:"160px"},

    //执业证记录
    practiceCode: {name:"执业证编码",type:"input",width:"140px"},
    attachment: {name:"附件URL",type:"input",width:"140px"},
    credential: {name:"证书地址",type:"input",width:"140px"},
    qrcode: {name:"二维码地址",type:"input",width:"140px"},
    credential2: {name:"粤省事图片证书地址",type:"input",width:"180px"},
    businessScope: {name:"业务范围",type:"input",width:"140px"},
    practiceArea: {name:"执业区域",type:"input",width:"140px"},
    effectiveTime:{name:"有效开始时间",type:"dateTime",width:"160px"},
    expirationTime:{name:"失效时间",type:"dateTime",width:"160px"},
    logoutDate:{name:"注销日期",type:"dateTime",width:"140px"},
    status:{name:"状态",type:"select",width:"140px",option:[{value:"F",label:"正常"},{value:"L",label:"无效"}]},
    printCount:{name:"打印总计次数",type:"input",width:"140px"},
    printTime:{name:"打印时间",type:"dateTime",width:"140px"},
    //诚信记录
    historyId:{name:"诚信导入历史id",type:"input",width:"140px"},
    reasonId: {name:"诚信行为",type:"input",width:"140px"},
    reasonDescription: {name:"行为描述",type:"input",width:"140px"},
    realScore: {name:"实际增/减分数",type:"input",width:"140px"},
    isLosses:{name:"是否损失金额",type:"input",width:"140px"},
    losses: {name:"损失金额",type:"input",width:"140px"},
    honorTime: {name:"表彰/处罚日期",type:"dateTime",width:"140px"},
    rewardCompany: {name:"评价主体",type:"input",width:"140px"},
    rewardCompanyType:{name:"评价主体级别",type:"input",width:"140px"},
    audit:{name:"审核状态",type:"input",width:"140px"},
    filesPath: {name:"表彰文件路径",type:"input",width:"200px"},
    fileName: {name:"表彰文件名列表",type:"input",width:"140px"},
    fileCodeName:{name:"表彰/处罚文件名称及文号",type:"input",width:"200px"},
    salesChannelType:{name:"渠道类型",type:"select",width:"140px",option:storeState.salesChannelType},
    firstCreditAuditId:{name:"复核ID",type:"input",width:"140px"},
    secordCreditAuditId: {name:"审核ID",type:"input",width:"140px"},

    //新版诚信记录新增
    reasonGeneraFormat: {name:"表彰/失信行为大类",type:"text",width:"140px"},
    RewardCompanyTypeFormat: {name:"表彰/失信行为认定主体类别",type:"text",width:"140px"},
    reasonClassFormat: {name:"失信行为细分类型",type:"text",width:"140px"},
    scoreLevelFormat: {name:"失信行为所属扣分层级",type:"text",width:"180px"},
    regulatoryRecordMattersFormat: {name:"行政监管措施记录",type:"text",width:"140px"},

    //诚信分数变更记录
    changeType:  {name:"变更类型",type:"select",width:"140px",option:[{value:1,label:"常规加减分"},{value:2,label:"年度未失信加分"},{value:3,label:"2020年培训扣分"}]},
    changeId: {name:"变更主体ID",type:"input",width:"140px"},
    score:  {name:"增/减分",type:"input",width:"140px"},
    changeDate:  {name:"变更时间",type:"dateTime",width:"140px"},
    //诚信级别
    changeLogId:{name:"执业证编码",type:"input",width:"140px"},
    creditLogId:{name:"诚信记录ID",type:"input",width:"140px"},
    oldCreditScore:{name:"旧诚信分数",type:"input",width:"140px"},
    newCreditScore: {name:"诚信分数",type:"input",width:"140px"},
    creditClass: {name:"诚信级别",type:"input",width:"140px"},

    //被解约信息
    personnelType: {name:"人员类型",type:"select",width:"140px",option:storeState.personnelType},
    contractBeginDate: {name:"合同起时间",type:"dateTime",width:"160px"},
    contractEndDate: {name:"合同终时间",type:"dateTime",width:"160px"},
    documentName: {name:"通报文件名称",type:"input",width:"140px"},
    documentNumber: {name:"通报文件文号",type:"input",width:"140px"},
    documentDate: {name:"报送时间",type:"dateTime",width:"160px"},
    punishmentDate:{name:"处罚时间",type:"dateTime",width:"160px"},
    rescissionDate: {name:"解约时间",type:"dateTime",width:"160px"},
    submitCompanyId: {name:"报送公司ID",type:"input",width:"140px"},

    //用户资质
    level: {name:"当前学习的最高课程等级",type:"input",width:"180px"},
    studyLevel: {name:"已学课程明细",type:"input",width:"160px"},
    studyCourses: {name:"业务范围",type:"input",width:"140px"},
    levelTime: {name:"最后更新资质等级时间",type:"dateTime",width:"180px"},

    //用户资质变更记录
    qualificationId: {name:"资质ID",type:"input",width:"140px"},
    oldLevel: {name:"旧的资质分级",type:"input",width:"140px"},
    newLevel: {name:"新的资质分级",type:"input",width:"140px"},
    msg: {name:"备注说明",type:"input",width:"140px"},

    //培训记录
    courseId: {name:"课程ID",type:"input",width:"140px"},
    agency: {name:"是否中介",type:"select",width:"140px",option:[{value:false,label:"否"},{value:true,label:"是"}]},
    trainingYear: {name:"所属年度",type:"input",width:"140px"},
    trainingType: {name:"培训方式",type:"select",width:"140px",option:storeState.trainingType},
    purchaseTime: {name:"购买时间",type:"dateTime",width:"160px"},
    trainingCompanyType: {name:"培训机构类型",type:"select",width:"160px",option:[{value:"G",label:"本机构"},{value:"L",label:"栗子学院"}]},
    credit: {name:"学分",type:"input",width:"140px"},
    inheritcredit: {name:"继承具体学分",type:"input",width:"140px"},
    preWork: {name:"岗前培训",type:"select",width:"140px",option:storeState.preWork},
    preTime: {name:"岗前培训完成时间",type:"dateTime",width:"160px"},
    passTime: {name:"达标时间",type:"dateTime",width:"160px"},
    offlineId: {name:"线下培训资料ID",type:"input",width:"160px"},
    frameProgress: {name:"大纲培训进度",type:"input",width:"160px"},
    
    //从业轨迹中间表
    sourceCompanyId:{name:"上一从业轨迹公司ID",type:"input",width:"160px"},
	sourceCompanyName:{name:"上一从业轨迹公司名称",type:"input",width:"180px"},
	sourceCompanyAbbreviation:{name:"上一从业轨迹公司简称",type:"input",width:"180px"},
	sourceFullLocation:{name:"上一从业轨迹公司所属区域全地址",type:"input",width:"240px"},
	sourcePracticeNumber:{name:"上一从业轨迹执业证编码",type:"input",width:"180px"} ,
	whereaboutsCompanyId:{name:"下一从业轨迹公司ID",type:"input",width:"180px"},
	whereaboutsCompanyName:{name:"下一从业轨迹公司名称",type:"input",width:"180px"},
	whereaboutsCompanyAbbreviation:{name:"下一从业轨迹公司简称",type:"input",width:"180px"},
	whereaboutsFullLocation:{name:"下一从业轨迹公司所属区域全地址",type:"input",width:"240px"},
	whereaboutsPracticeNumber:{name:"下一从业轨迹执业证编码",type:"input",width:"180px"},
	sortid:{name:"公司排序",type:"input",width:"160px"},
    current:  {name:"是否当前从业轨迹",type:"select",width:"160px",option:[{value:0,label:"否"},{value:1,label:"是"}]},
    workingTime: {name:"公司从业时长",type:"input",width:"160px"},
    allWorkingTime: {name:"从业总时长",type:"input",width:"160px"},
    workingCount: {name:"从业公司数量",type:"input",width:"160px"},
    mainWorkingTime: {name:"主体从业时长",type:"input",width:"160px"},
}
console.log(storeState);
export default fieldName;